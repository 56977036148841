import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { TypeImageLink } from '../../../@types/generated';
import PartnerLogosLayout from '../component-library/layout/PartnerLogosLayout';
import SectionBackground from '../component-library/SectionBackground';
import { PageSectionComponent } from './pageSections';
import sectionToCompatibleProps from './util/sectionToCompatibleProps';

const PartnerLogosSection: PageSectionComponent<null> = ({ section }) => {
  const { id } = sectionToCompatibleProps({ section });
  const title = section?.fields?.title && documentToPlainTextString(section.fields.title);
  return (
    <SectionBackground id={id}>
      <PartnerLogosLayout
        title={title}
        logosList={(section.fields.references as TypeImageLink[])?.map((section) => ({
          link: section.fields.link || '',
          image: {
            url: section.fields.image?.fields.file?.url || '',
            fileName: section.fields.image?.fields.title || '',
          },
        }))}
      />
    </SectionBackground>
  );
};

export default PartnerLogosSection;
